<script setup lang="ts">
import type { IToast } from '~/services/toast.service';
import { useAuthStore } from '~/stores/auth.store';
const { $contentService, $toastService } = useServices();
interface IProps {
  toast: IToast | undefined;
}
const props = withDefaults(defineProps<IProps>(), {});

const authStore = useAuthStore();
const kenticoContent = ref<any | null>(null);
const visible: globalThis.Ref<boolean> = ref<boolean>(false);

function closeToast() {
  const el: HTMLElement = document.getElementById('close-toast');
  if (!!el) {
    el.onclick = () => {
      // $toastService.toastService.setToastViewed(props.toast);
      authStore.toggleAccountModal(false);
      visible.value = false;
    };
  }
}

function markToastAsViewed() {
  $toastService.toastService.setToastViewed(props.toast);
  authStore.toggleAccountModal(false);
  visible.value = false;
}

const kentico = ref(null);

watchEffect(async () => {
  if (!props.toast || !props.toast.contentPath) return;
  const { content } = await $contentService.fetchContent(
    props.toast.contentPath.replaceAll('%2F', '/'),
    'cms1.jpc.africa'
  );
  kenticoContent.value = content;
  if (!!kenticoContent.value) {
    visible.value = true;
  }
});

watch(
  () => kentico.value,
  (value) => {
    if (!!value) closeToast();
  }
);
</script>

<template>
  <LazyDesignSystemModal
    :active="visible"
    modal
    :show-header="false"
    content-style="background: transparent; box-shadow: none; border: none;"
    :display-background="false"
    :display-border="false"
  >
    <template #modal-content>
      <div
        v-if="kenticoContent"
        ref="kentico"
        @click="markToastAsViewed()"
        v-html="kenticoContent"
      />
    </template>
  </LazyDesignSystemModal>
</template>

<style scoped></style>
